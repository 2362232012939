import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import "./App.css";

import SignIn from "./SignIn";
import Main from "./Main";

firebase.initializeApp({
  apiKey: "AIzaSyDeJoGAgRrPsoAXmzQxuEP8Xr0lxqwGM7g",
  authDomain: "finances-1545965105405.firebaseapp.com",
  projectId: "finances-1545965105405",
});

const db = firebase.firestore();
// firebase.firestore.setLogLevel("debug");
const auth = firebase.auth();

const App = () => {
  const [user, setUser] = useState(false);

  auth.onAuthStateChanged((user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(false);
    }
  });
  return (
    <>
      {user ? <Main user={user} db={db} auth={auth} /> : <SignIn auth={auth} />}
    </>
  );
};

export default App;
