import React, { useState, useEffect, Suspense } from "react";
import {
  Navbar,
  NavbarGroup,
  NavbarHeading,
  Button,
  Alignment,
  Classes,
  Card,
  Alert,
  Intent,
  Spinner,
} from "@blueprintjs/core";

import "./Main.css";

const TransactionItem = React.lazy(() => import("./TransactionItem"));

const signOut = (auth) => {
  auth
    .signOut()
    .then(function () {
      // Sign-out successful.
    })
    .catch(function (error) {
      throw new Error(error);
    });
};

const selectAll = (
  transactions,
  selectedTransactionIds,
  setSelectedTransactionIds
) => {
  let newSelectedTransactions = [];
  transactions.forEach((txn) => {
    if (!selectedTransactionIds.includes(txn)) {
      newSelectedTransactions.push(txn.id);
    }
  });

  setSelectedTransactionIds([
    ...selectedTransactionIds,
    ...newSelectedTransactions,
  ]);
};

const deleteBulk = (
  transactionIds,
  db,
  user,
  setIsLoading,
  setSelectedTransactionIds
) => {
  const batch = db.batch();
  transactionIds.forEach((txnId) => {
    batch.delete(
      db.collection("users/" + user.email + "/transactions").doc(txnId)
    );
  });
  batch
    .commit()
    .then(() => {
      console.log("done");
      setIsLoading(false);
      setSelectedTransactionIds([]);
    })
    .catch((error) => {
      console.error(error);
    });
  console.log("outside");
};

const Main = ({ user, db, auth }) => {
  const numPerPage = 20;

  const [transactions, setTransactions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cycles, setCycles] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTransactionIds, setSelectedTransactionIds] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationisOpen] = useState(
    false
  );

  useEffect(() => {
    db.collection(`users/${user.email}/transactions`)
      .orderBy("date_string", "desc")
      .orderBy("date_added", "desc")
      .onSnapshot((querySnapshot) => {
        let transactionsStore = [];
        querySnapshot.forEach((doc) => {
          const newDoc = { id: doc.id, ...doc.data() };
          transactionsStore.push(newDoc);
        });
        setTransactions(transactionsStore);
        setTotalPages(Math.ceil(transactionsStore.length / numPerPage));
      });

    db.collection(`users/${user.email}/categories`).onSnapshot(
      (querySnapshot) => {
        let categoriesStore = [];
        querySnapshot.forEach((doc) => {
          const newDoc = { id: doc.id, ...doc.data() };
          categoriesStore.push(newDoc);
        });
        setCategories(categoriesStore);
      }
    );

    db.collection(`users/${user.email}/cycles`).onSnapshot((querySnapshot) => {
      let cyclesStore = [];
      querySnapshot.forEach((doc) => {
        const newDoc = { id: doc.id, ...doc.data() };
        cyclesStore.push(newDoc);
      });
      setCycles(cyclesStore);
    });
  });

  const onSelectTransaction = (transactionId) => {
    if (selectedTransactionIds.includes(transactionId)) {
      const index = selectedTransactionIds.indexOf(transactionId);
      const updatedTransactions = [
        ...selectedTransactionIds.slice(0, index),
        ...selectedTransactionIds.slice(
          index + 1,
          selectedTransactionIds.length
        ),
      ];
      setSelectedTransactionIds(updatedTransactions);
    } else {
      const updatedTransactions = [...selectedTransactionIds, transactionId];
      setSelectedTransactionIds(updatedTransactions);
    }
  };

  const transactionsToDisplay = transactions.slice(
    (currentPage - 1) * numPerPage,
    currentPage * numPerPage
  );

  return (
    <>
      <Navbar>
        <NavbarGroup align={Alignment.LEFT}>
          <NavbarHeading>Finance Manager</NavbarHeading>
        </NavbarGroup>
        <NavbarGroup align={Alignment.RIGHT}>
          <Button
            className={Classes.MINIMAL}
            icon="log-out"
            text="Sign Out"
            onClick={() => signOut(auth)}
          />
        </NavbarGroup>
      </Navbar>

      <div id="page-container">
        {selectedTransactionIds.length ? (
          <>
            <Button
              onClick={() => {
                setSelectedTransactionIds([]);
              }}
            >
              Deselect All
            </Button>
            <Button
              onClick={() => {
                selectAll(
                  transactionsToDisplay,
                  selectedTransactionIds,
                  setSelectedTransactionIds
                );
              }}
            >
              Select All On This Page
            </Button>
            <p>Selected {selectedTransactionIds.length} transactions</p>
            <Button>Group</Button>
            <Button>Add to existing group</Button>
            <Button>Update Date</Button>
            <Button>Update Category</Button>
            <Button>Update Cycle</Button>
            <Button
              onClick={() => {
                setDeleteConfirmationisOpen(true);
              }}
            >
              Delete
            </Button>
          </>
        ) : (
          <Button
            onClick={() => {
              selectAll(
                transactionsToDisplay,
                selectedTransactionIds,
                setSelectedTransactionIds
              );
            }}
          >
            Select All On This Page
          </Button>
        )}
        {transactions.length && (
          <>
            <p>
              Page {currentPage} of {totalPages}
            </p>
            {currentPage !== 1 && (
              <p>
                <Button
                  onClick={() => {
                    setCurrentPage(1);
                  }}
                >
                  First Page
                </Button>
                <Button
                  onClick={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                >
                  Previous Page
                </Button>
              </p>
            )}
            {currentPage !== totalPages && (
              <p>
                <Button
                  onClick={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                >
                  Next Page
                </Button>
                <Button
                  onClick={() => {
                    setCurrentPage(totalPages);
                  }}
                >
                  Last Page
                </Button>
              </p>
            )}

            {transactionsToDisplay.map((transaction) => (
              <Suspense key={transaction.id} fallback={<Card>Loading...</Card>}>
                <TransactionItem
                  transaction={transaction}
                  categories={categories}
                  cycles={cycles}
                  onClick={() => {
                    onSelectTransaction(transaction.id);
                  }}
                  selected={selectedTransactionIds.includes(transaction.id)}
                />
              </Suspense>
            ))}
          </>
        )}
      </div>

      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Delete All"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={deleteConfirmationIsOpen}
        onCancel={() => {
          setDeleteConfirmationisOpen(false);
        }}
        onConfirm={() => {
          setIsLoading(true);
          deleteBulk(
            selectedTransactionIds,
            db,
            user,
            setIsLoading,
            setSelectedTransactionIds
          );
          setDeleteConfirmationisOpen(false);
        }}
      >
        <p>
          Are you sure you want to delete all {selectedTransactionIds.length}{" "}
          transactions?
        </p>
      </Alert>

      {isLoading && (
        <div id="loading">
          <Spinner size={50} />
        </div>
      )}
    </>
  );
};

export default Main;
